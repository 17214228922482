<template>
    <v-dialog :persistent="loading" v-model="dialog" max-width="500">
        <template v-slot:activator="{ on: dialog }">
            <v-btn :disabled="disabled" v-if="isIconDialog === false" v-on="{ ...dialog }" text class="font-weight-bold" color="primary">
                <v-icon>add</v-icon>create new token
            </v-btn>
            <v-tooltip v-else bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn v-on="{ ...tooltip, ...dialog }" icon
                        ><v-icon>{{ activatorIcon }}</v-icon></v-btn
                    >
                </template>
                <span>Edit</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title>
                <div v-if="dialogAction === dialogActions.CREATE_NEW_TOKEN" class="d-flex align-center primary--text">
                    <v-icon class="mr-1">add</v-icon>
                    Create Token
                </div>
                <div v-else-if="dialogAction === dialogActions.EDIT_TOKEN" class="d-flex align-center primary--text">
                    <v-icon class="mr-1">edit</v-icon>
                    Edit Token
                </div>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-divider></v-divider>
            <v-card-text>
                <v-form v-model="valid">
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold primary--text mt-3 text-uppercase">select token scope</span>
                        <v-select
                            v-model="newTokenScope"
                            :rules="newTokenFormRules"
                            :items="Object.keys(orgTokenScopes)"
                            label="Token scope*"
                            required
                        ></v-select>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold primary--text mt-3 text-uppercase">select token type</span>
                        <v-select v-model="newTokenType" :rules="newTokenFormRules" :items="Object.keys(orgTokenTypes)" label="Token type*" required></v-select>
                    </div>
                    <div v-if="newTokenScope && newTokenType && orgTokenTypes[newTokenType] === orgTokenTypes.XPRESSFEED" class="d-flex flex-column">
                        <span class="font-weight-bold primary--text mt-3 text-uppercase">provide token details</span>
                        <v-text-field
                            autocomplete="new-username"
                            v-model="newTokenUsername"
                            :rules="newTokenFormRules"
                            label="Username*"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="newTokenPassword"
                            autocomplete="new-password"
                            :rules="newTokenFormRules"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                            label="Password*"
                            required
                            @click:append="showPassword = !showPassword"
                        ></v-text-field>
                    </div>
                    <div v-else-if="newTokenScope && newTokenType">
                        <v-text-field
                            autocomplete="new-token"
                            v-model="newTokenValue"
                            :rules="newTokenFormRules"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                            label="Token*"
                            required
                            @click:append="showPassword = !showPassword"
                        >
                        </v-text-field>
                    </div>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="loading" color="primary" text @click="dialog = false">Close</v-btn>
                <v-btn
                    v-if="dialogAction === dialogActions.CREATE_NEW_TOKEN"
                    :loading="loading"
                    :disabled="valid === false"
                    color="secondary"
                    text
                    @click="createNewToken"
                    >create</v-btn
                >
                <v-btn
                    v-else-if="dialogAction === dialogActions.EDIT_TOKEN"
                    :loading="loading"
                    :disabled="valid === false"
                    color="error"
                    text
                    @click="editToken"
                    >confirm</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { enumsData } from '@/mixins/enums'
import { isJson } from '@/utils'
function defaultData() {
    return {
        dialog: false,
        newTokenFormRules: [v => !!v || 'Required field'],
        valid: false,
        newTokenUsername: '',
        newTokenPassword: '',
        newTokenValue: '',
        newTokenScope: null,
        newTokenType: null,
        loading: false,
        dialogActions: {
            CREATE_NEW_TOKEN: 'createToken',
            EDIT_TOKEN: 'editToken'
        },
        showPassword: false
    }
}
export default {
    name: 'CreateAndEditTokenDialog',
    mixins: [enumsData],
    props: {
        dialogAction: { type: String, default: 'createToken' },
        isIconDialog: {
            type: Boolean,
            default: false
        },
        activatorIcon: { type: String, default: null },
        tokenData: {
            type: Object,
            default: null
        },
        disabled: Boolean
    },
    data() {
        return defaultData()
    },
    mounted() {
        if (this.$props.tokenData) {
            if (this.$props.dialogAction === this.$data.dialogActions.EDIT_TOKEN) {
                this.$data.newTokenScope = Object.keys(this.$data.orgTokenScopes).find(key => this.$data.orgTokenScopes[key] === this.$props.tokenData.scope)
                this.$data.newTokenType = Object.keys(this.$data.orgTokenTypes).find(key => this.$data.orgTokenTypes[key] === this.$props.tokenData.token_type)
            }
            if (isJson(this.$props.tokenData.token) === true) {
                this.$data.newTokenUsername = JSON.parse(this.$props.tokenData.token).username
                this.$data.newTokenPassword = JSON.parse(this.$props.tokenData.token).password
            } else {
                this.$data.newTokenValue = this.$props.tokenData.token
            }
        }
    },
    methods: {
        createNewToken: function() {
            this.$data.loading = true
            var postBody = {
                token_type: this.$data.orgTokenTypes[this.$data.newTokenType],
                scope: this.$data.orgTokenScopes[this.$data.newTokenScope]
            }
            if (this.$data.orgTokenTypes[this.$data.newTokenType] === this.$data.orgTokenTypes.XPRESSFEED) {
                const tokenString = { username: this.$data.newTokenUsername, password: this.$data.newTokenPassword }
                postBody.token = JSON.stringify(tokenString)
            } else {
                postBody.token = this.$data.newTokenValue
            }
            this.$axios
                .post(`/orgs/${this.$route.params.oid}/org_tokens`, postBody)
                .then(response => {
                    this.$store.dispatch('orgStore/fetchOrgTokens', this.$route.params.oid)
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'New token has been created!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to create new token.',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.$data.loading = false
                    this.$data.dialog = false
                })
        },
        editToken: function() {
            this.$data.loading = true
            var patchBody = {
                token_type: this.$data.orgTokenTypes[this.$data.newTokenType],
                scope: this.$data.orgTokenScopes[this.$data.newTokenScope]
            }
            if (this.$data.orgTokenTypes[this.$data.newTokenType] === this.$data.orgTokenTypes.XPRESSFEED) {
                const tokenString = { username: this.$data.newTokenUsername, password: this.$data.newTokenPassword }
                patchBody.token = JSON.stringify(tokenString)
            } else {
                patchBody.token = this.$data.newTokenValue
            }
            this.$axios
                .patch(`/org_tokens/${this.$props.tokenData.otknid}`, patchBody)
                .then(response => {
                    this.$store.dispatch('orgStore/fetchOrgTokens', this.$route.params.oid)
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Token info has been updated!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to update token info.',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.$data.loading = false
                    this.$data.dialog = false
                })
        }
    },
    watch: {
        dialog: function(nextVal, preVal) {
            if (nextVal === false && preVal === true && this.$props.dialogAction === this.$data.dialogActions.CREATE_NEW_TOKEN) {
                Object.assign(this.$data, defaultData())
            }
        }
    }
}
</script>
